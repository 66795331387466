import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Card, Divider, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Delete as DeleteIcon } from '@mui/icons-material';

import itemTransferFormModel from '../models/createItemTransferModel';
import { useGetDevelopmentsByIdQuery, useGetDevelopmentsQuery, useGetGrvsDropdownQuery, useGetInventoryLocationsQuery, useGetItemsQuery, useGetStakeholdersByTypeByTypeQuery, useGetUnitsByProjectByProjectIdQuery } from '../../../redux/vmsApi';

const {
    formField: {
        items: {
            fields: itemFields
        },
        sourceLocationType,
        destinationLocationType,
        sourceLocation: {
            fields: locationSourceFields
        },
        destinationLocation: {
            fields: locationDestinationFields
        },
    },
} = itemTransferFormModel;

interface OwnProps {
    editing?: boolean;
}

const CreateItemTransferForm = () => {

    const { data: stakeholders } = useGetStakeholdersByTypeByTypeQuery({ type: 'Supplier' });
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();

    const { data: inventoryLocations, isLoading, isError } = useGetInventoryLocationsQuery({});
    const { data: grvs, } = useGetGrvsDropdownQuery();

    const [selectedSourceDevelopment, setSelectedSourceDevelopment] = useState('');
    const [selectedSourceProject, setSelectedSourceProject] = useState('');
    const [selectedSourceUnit, setSelectedSourceUnit] = useState('');
    const [selectedSourceLocation, setSelectedSourceLocation] = useState('');
    const [selectedSourceSupplier, setSelectedSourceSupplier] = useState('');


    const { data: sourceDevelopmentData } = useGetDevelopmentsByIdQuery(
        { id: selectedSourceDevelopment },
        { skip: !selectedSourceDevelopment }
    );

    const { data: sourceUnits = [], refetch: refetchSourceUnits } =
        useGetUnitsByProjectByProjectIdQuery(
            { projectId: selectedSourceProject },
            { skip: !selectedSourceProject }
        );



    const [selectedDestinationDevelopment, setSelectedDestinationDevelopment] = useState('');
    const [selectedDestinationProject, setSelectedDestinationProject] = useState('');
    const [selectedDestinationUnit, setSelectedDestinationUnit] = useState('');

    const [selectedDestinationLocation, setSelectedDestinationLocation] = useState('');
    const [selectedDestinationSupplier, setSelectedDestinationSupplier] = useState('');

    const { data: destinationDevelopmentData } = useGetDevelopmentsByIdQuery(
        { id: selectedDestinationDevelopment },
        { skip: !selectedDestinationDevelopment }
    );


    const { data: developments = [] } = useGetDevelopmentsQuery();
    const { data: items = [] } = useGetItemsQuery({});
    const { data: units = [], refetch: refetchUnits } = useGetUnitsByProjectByProjectIdQuery(
        { projectId: selectedSourceProject },
        { skip: !selectedSourceProject }
    );

    const { data: destinationUnits = [], refetch: refetchDestinationUnits } =
        useGetUnitsByProjectByProjectIdQuery(
            { projectId: selectedDestinationProject },
            { skip: !selectedDestinationProject }
        );


    const getProjects = (developmentId: string | undefined) =>
        developments.find((dev) => dev.id === developmentId)?.projects || [];

    const handleSourceDevelopmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const developmentId = event.target.value;
        setSelectedSourceDevelopment(developmentId);
        setFieldValue('sourceLocation.development.id', developmentId);
        setFieldValue('sourceLocation.project.id', '');
        setFieldValue('sourceLocation.unit.id', '');
        setSelectedSourceProject('');
        setSelectedSourceUnit('');
    };

    const handleSourceProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const projectId = event.target.value;
        setSelectedSourceProject(projectId);
        const selectedProjectObject = getProjects(selectedSourceDevelopment).find(project => project.id === projectId);
        if (selectedProjectObject) {
            setFieldValue('sourceLocation.project.name', selectedProjectObject.name);
        }
        setFieldValue('sourceLocation.project.id', projectId);
        setFieldValue('sourceLocation.unit.id', '');
        setSelectedSourceUnit('');
    };

    const handleSourceUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const unitId = event.target.value;
        setSelectedSourceUnit(unitId);
        setFieldValue('sourceLocation.unit.id', unitId);

        const unitObject = sourceUnits.find(unit => unit.id === unitId);
        const unitName = unitObject ? unitObject.unitName : '';
        setFieldValue('sourceLocation.unit.name', unitName);
    };

    const handleSourceLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
        const locationId = event.target.value;
        setSelectedSourceLocation(locationId);

        const locationObject = inventoryLocations?.find(location => location.id === locationId);
        const locationCode = locationObject ? locationObject.locationCode : '';
        const locationDescription = locationObject ? locationObject.description : '';
        const developmentName = locationObject ? locationObject?.development?.name : '';
        const developmentId = locationObject ? locationObject?.development?.id : '';

        setFieldValue('sourceLocation.inventoryLocation.locationId', locationId);
        setFieldValue('sourceLocation.inventoryLocation.locationCode', locationCode);
        setFieldValue('sourceLocation.inventoryLocation.description', locationDescription);
        setFieldValue('sourceLocation.inventoryLocation.developmentId', developmentId);
        setFieldValue('sourceLocation.inventoryLocation.developmentName', developmentName);
    };

    const handleSourceSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
        const supplierId = event.target.value;
        setSelectedSourceSupplier(supplierId);

        const supplierObject = stakeholders?.find(supplier => supplier.id === supplierId);
        const supplierName = supplierObject ? supplierObject.name : '';
        setFieldValue('sourceLocation.supplier.id', supplierId);
        setFieldValue('sourceLocation.supplier.name', supplierName);
    };

    const handleDestinationDevelopmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const developmentId = event.target.value;
        setSelectedDestinationDevelopment(developmentId);
        setFieldValue('destinationLocation.development.id', developmentId);
        setFieldValue('destinationLocation.project.id', '');
        setFieldValue('destinationLocation.unit.id', '');
        setSelectedDestinationProject('');
        setSelectedDestinationUnit('');
    };

    const handleDestinationProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const projectId = event.target.value;
        setSelectedDestinationProject(projectId);

        const selectedProjectObject = getProjects(selectedDestinationDevelopment).find(project => project.id === projectId);
        if (selectedProjectObject) {
            setFieldValue('destinationLocation.project.name', selectedProjectObject.name);
        }
        setFieldValue('destinationLocation.project.id', projectId);
        setFieldValue('destinationLocation.unit.id', '');
        setSelectedDestinationUnit('');
    };

    const handleDestinationUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const unitId = event.target.value;
        setSelectedDestinationUnit(unitId);
        setFieldValue('destinationLocation.unit.id', unitId);
        const unitObject = destinationUnits.find(unit => unit.id === unitId);
        const unitName = unitObject ? unitObject.unitName : '';
        setFieldValue('destinationLocation.unit.name', unitName);
    };


    const handleDestinationLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
        const locationId = event.target.value;
        setSelectedDestinationLocation(locationId);
        const locationObject = inventoryLocations?.find(location => location.id === locationId);
        const locationCode = locationObject ? locationObject.locationCode : '';
        const locationDescription = locationObject ? locationObject.description : '';
        const developmentName = locationObject ? locationObject?.development?.name : '';
        const developmentId = locationObject ? locationObject?.development?.id : '';

        setFieldValue('destinationLocation.inventoryLocation.locationId', locationId);
        setFieldValue('destinationLocation.inventoryLocation.locationCode', locationCode);
        setFieldValue('destinationLocation.inventoryLocation.description', locationDescription);
        setFieldValue('destinationLocation.inventoryLocation.developmentId', developmentId);
        setFieldValue('destinationLocation.inventoryLocation.developmentName', developmentName);
    };

    const handleDestinationSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
        const supplierId = event.target.value;
        setSelectedDestinationSupplier(supplierId);

        const supplierObject = stakeholders?.find(supplier => supplier.id === supplierId);
        const supplierName = supplierObject ? supplierObject.name : '';
        setFieldValue('destinationLocation.supplier.id', supplierId);
        setFieldValue('destinationLocation.supplier.name', supplierName);
    };


    const handleItemSelectionChange = (newValue: any | null, index: number) => {
        if (newValue) {
            setFieldValue(`items[${index}].itemId`, newValue.id);
            setFieldValue(`items[${index}].itemCode`, newValue.itemCode);
            setFieldValue(`items[${index}].description`, newValue.description);
            setFieldValue(`items[${index}].itemCost`, newValue.itemCost);
            setFieldValue(`items[${index}].quantity.quantityUnit`, newValue.quantityUnit);
        } else {
           
            setFieldValue(`items[${index}].itemId`, '');
            setFieldValue(`items[${index}].itemCode`, '');
            setFieldValue(`items[${index}].description`, '');
            setFieldValue(`items[${index}].itemCost`, '');
            setFieldValue(`items[${index}].quantity.quantityValue`, '');
            setFieldValue(`items[${index}].quantity.quantityUnit`, '');
        }
    };


    useEffect(() => {
        if (sourceDevelopmentData) {
            setFieldValue('sourceLocation.development.name', sourceDevelopmentData.name);
        }
    }, [sourceDevelopmentData, setFieldValue]);

    useEffect(() => {
        if (destinationDevelopmentData) {
            setFieldValue('destinationLocation.development.name', destinationDevelopmentData.name);
        }
    }, [destinationDevelopmentData, setFieldValue]);

    useEffect(() => {
        if (selectedSourceProject) {
            refetchUnits();
        }
    }, [selectedSourceProject, refetchUnits]);

    useEffect(() => {
        if (selectedDestinationProject) {
            refetchDestinationUnits();
        }
    }, [selectedDestinationProject, refetchDestinationUnits]);

    return (
        <Stack spacing={5} width="100%">
            <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                {'Add'} Item Transfer
            </Typography>


            <Card variant="outlined" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                    Transfer From (Source Location)
                </Typography>
                <Divider style={{ marginBottom: '15px' }} />
                <Stack spacing={2}>




                    <TextField
                        select
                        fullWidth
                        {...sourceLocationType}
                        value={values.sourceLocationType}
                        autoComplete="on"
                        onChange={(event: any) => {
                            setFieldValue('sourceLocationType', event.target.value);
                        }}
                    >
                        <MenuItem value="unit">Unit</MenuItem>
                        <MenuItem value="inventoryLocation">Inventory Location</MenuItem>
                        <MenuItem value="supplier">Supplier</MenuItem>
                    </TextField>

                    {values.sourceLocationType === 'unit' && (
                        <>

                            <TextField

                                {...locationSourceFields.development}
                                select
                                value={selectedSourceDevelopment}
                                onChange={handleSourceDevelopmentChange}
                                fullWidth
                            >
                                {developments.map((dev) => (
                                    <MenuItem key={dev.id} value={dev.id}>
                                        {dev.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                {...locationSourceFields.project}
                                select
                                value={selectedSourceProject}
                                onChange={handleSourceProjectChange}
                                fullWidth
                                disabled={!getProjects(selectedSourceDevelopment).length}
                            >
                                {getProjects(selectedSourceDevelopment).map((proj) => (
                                    <MenuItem key={proj.id} value={proj.id}>
                                        {proj.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Autocomplete
                                options={units || []}
                                getOptionLabel={(option) => option.unitName}
                                value={units.find((unit) => unit.id === selectedSourceUnit) || null}
                                onChange={(event, newValue) => {
                                    const syntheticEvent = {
                                        target: { value: newValue?.id || '' },
                                    } as React.ChangeEvent<HTMLInputElement>;
                                    handleSourceUnitChange(syntheticEvent);
                                }}
                                disabled={!units.length}
                                style={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Unit"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                        </>
                    )}

                    {values.sourceLocationType === 'inventoryLocation' && (
                        <>


                            <Autocomplete
                                options={inventoryLocations || []}
                                getOptionLabel={(option) => `${option.locationCode}, ${option.description}`}
                                value={inventoryLocations?.find((loc) => loc.id === selectedSourceLocation) || null}
                                onChange={(event, newValue) => {
                                    const syntheticEvent = {
                                        target: { value: newValue?.id || '' },
                                    } as React.ChangeEvent<HTMLInputElement>;
                                    handleSourceLocation(syntheticEvent);
                                }}
                                style={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Location"
                                        variant="outlined"
                                        sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                        error={touched[locationSourceFields.inventoryLocation.name] && Boolean(errors[locationSourceFields.inventoryLocation.name])}
                                        helperText={
                                            touched[locationSourceFields.inventoryLocation.name] && errors[locationSourceFields.inventoryLocation.name]
                                                ? errors[locationSourceFields.inventoryLocation.name]
                                                : ''
                                        }
                                    />
                                )}
                            />




                        </>
                    )}

                    {values.sourceLocationType === 'supplier' && (
                        <Autocomplete
                            options={stakeholders || []}
                            getOptionLabel={(option) => option.name}
                            value={stakeholders?.find((stakeholder) => stakeholder.id === selectedSourceSupplier) || null}
                            onChange={(event, newValue) => {
                                const syntheticEvent = {
                                    target: { value: newValue?.id || '' },
                                } as React.ChangeEvent<HTMLInputElement>;
                                handleSourceSupplier(syntheticEvent);
                            }}
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Supplier"
                                    variant="outlined"
                                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />
                            )}
                        />

                    )}
                </Stack>

            </Card>


            <Card variant="outlined" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                    Transfer To (Destination Location)
                </Typography>
                <Divider style={{ marginBottom: '15px' }} />


                <Stack spacing={2}>

                    <TextField
                        select
                        fullWidth
                        {...destinationLocationType}
                        value={values.destinationLocationType}
                        autoComplete="on"
                        onChange={(event: any) => {
                            setFieldValue('destinationLocationType', event.target.value);
                        }}
                    >
                        <MenuItem value="unit">Unit</MenuItem>
                        <MenuItem value="inventoryLocation">Inventory Location</MenuItem>
                        <MenuItem value="supplier">Supplier</MenuItem>
                    </TextField>

                    {values.destinationLocationType === 'unit' && (
                        <>
                            <TextField
                                {...locationDestinationFields.development}
                                select
                                value={selectedDestinationDevelopment}
                                onChange={handleDestinationDevelopmentChange}
                                fullWidth
                            >
                                {developments.map((dev) => (
                                    <MenuItem key={dev.id} value={dev.id}>
                                        {dev.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                {...locationDestinationFields.project}
                                select
                                value={selectedDestinationProject}
                                onChange={handleDestinationProjectChange}
                                fullWidth
                                disabled={!getProjects(selectedDestinationDevelopment).length}
                            >
                                {getProjects(selectedDestinationDevelopment).map((proj) => (
                                    <MenuItem key={proj.id} value={proj.id}>
                                        {proj.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Autocomplete
                                options={destinationUnits || []}
                                getOptionLabel={(option) => option.unitName}
                                value={destinationUnits.find((unit) => unit.id === selectedDestinationUnit) || null}
                                onChange={(event, newValue) => {
                                    const syntheticEvent = {
                                        target: { value: newValue?.id || '' },
                                    } as React.ChangeEvent<HTMLInputElement>;
                                    handleDestinationUnitChange(syntheticEvent);
                                }}
                                disabled={!destinationUnits.length}
                                style={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Destination Unit"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                        </>
                    )}

                    {values.destinationLocationType === 'inventoryLocation' && (
                        <>

                            <>
                                <Autocomplete
                                    options={inventoryLocations || []}
                                    getOptionLabel={(option) => `${option.locationCode}, ${option.description}`}
                                    value={inventoryLocations?.find((loc) => loc.id === selectedDestinationLocation) || null}
                                    onChange={(event, newValue) => {
                                        const syntheticEvent = {
                                            target: { value: newValue?.id || '' },
                                        } as React.ChangeEvent<HTMLInputElement>;
                                        handleDestinationLocation(syntheticEvent);
                                    }}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Destination Location"
                                            variant="outlined"
                                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                            error={
                                                touched[locationDestinationFields.inventoryLocation?.name] &&
                                                Boolean(errors[locationDestinationFields.inventoryLocation?.name])
                                            }
                                            helperText={
                                                touched[locationDestinationFields.inventoryLocation?.name] &&
                                                    errors[locationDestinationFields.inventoryLocation?.name]
                                                    ? errors[locationDestinationFields.inventoryLocation?.name]
                                                    : ''
                                            }
                                        />
                                    )}
                                />

                            </>

                        </>
                    )}

                    {values.destinationLocationType === 'supplier' && (

                        <Autocomplete
                            options={stakeholders || []}
                            getOptionLabel={(option) => option.name}
                            value={stakeholders?.find((stakeholder) => stakeholder.id === selectedDestinationSupplier) || null}
                            onChange={(event, newValue) => {
                                const syntheticEvent = {
                                    target: { value: newValue?.id || '' },
                                } as React.ChangeEvent<HTMLInputElement>;
                                handleDestinationSupplier(syntheticEvent);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Supplier"
                                    variant="outlined"
                                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />
                            )}
                        />

                    )}

                </Stack>
            </Card>


            <FieldArray name="items">
                {({ push, remove }) => (
                    <>
                        {values.items.map((item: any, index: number) => (
                            <Card
                                variant="outlined"
                                key={index}
                                style={{
                                    padding: '20px',
                                    borderRadius: '8px',
                                    marginBottom: '15px',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    style={{ fontWeight: 'bold' }}
                                >
                                    Item Information
                                </Typography>
                                <Divider style={{ marginBottom: '15px' }} />
                                <Stack spacing={2}>
                                   
                                    <Autocomplete
                                        options={items || []}
                                        getOptionLabel={(option) =>
                                            `${option.itemCode} (${option.description})`
                                        }
                                        value={
                                            items.find(
                                                (opt) => opt.id === values.items[index]?.itemId
                                            ) || null
                                        }
                                        onChange={(event, newValue) =>
                                            handleItemSelectionChange(newValue, index)
                                        }
                                        style={{ width: '100%' }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Item"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                    
                                    <Field {...itemFields.itemId} type="hidden" />
                                    <Field {...itemFields.itemCode} type="hidden" />
                                    <Field {...itemFields.itemCost} type="hidden" />
                                    <Field {...itemFields.description} type="hidden" />
                                    <Field {...itemFields.quantity.quantityValue} type="hidden" />
                                    <Field {...itemFields.quantity.quantityUnit} type="hidden" />

                                   
                                    <TextField
                                        {...itemFields.quantity.quantityValue}
                                        type="number"
                                        fullWidth
                                        value={values.items[index]?.quantity.quantityValue || ''}
                                        onChange={(e) =>
                                            setFieldValue(
                                                `items[${index}].quantity.quantityValue`,
                                                Number(e.target.value)
                                            )
                                        }
                                    />

                                    
                                    <TextField
                                        {...itemFields.reasonForTransfer}
                                        fullWidth
                                        value={values.items[index]?.reasonForTransfer || ''}
                                        onChange={(e) =>
                                            setFieldValue(
                                                `items[${index}].reasonForTransfer`,
                                                e.target.value
                                            )
                                        }
                                    />
                                </Stack>

                               
                                <IconButton color="error" onClick={() => remove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Card>
                        ))}

                        
                        <Button
                            onClick={() =>
                                push({
                                    itemId: '',
                                    itemCode: '',
                                    description: '',
                                    itemCost: '',
                                    quantity: { quantityValue: '', quantityUnit: '' },
                                    reasonForTransfer: '',
                                })
                            }
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ mt: 2 }}
                        >
                            Add Item
                        </Button>
                    </>
                )}
            </FieldArray>


        </Stack>
    );
};

export default CreateItemTransferForm;