import React, { createContext } from 'react';

// Define the structure of actions
interface Actions {
  scan: string | null;
}

// Define the context structure
interface ActionsContextType {
  actions: Actions;
  setActions: React.Dispatch<React.SetStateAction<Actions>>;
}

// Create the context with default values
export const ActionsContext = createContext<ActionsContextType>({
  actions: { scan: null },
  setActions: () => {},
});
