import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from "@mui/material";
import { Form, Formik } from 'formik';



import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ItemTransfer, NewItemTransfer, usePostItemTransfersMutation } from '../../../redux/vmsApi';
import createItemTransferModel, { } from '../models/createItemTransferModel';
import LoadingCover from '../../common/LoadingCover';
import CreateItemTransferForm from '../forms/CreateItemTransferForm';




let newItemTransfer: NewItemTransfer;
type MyFormValues = NewItemTransfer

const createItemTransferModelInitial = createItemTransferModel.initialValues

type Props = {
  onSuccess?: (ItemTransfer: ItemTransfer) => void;
};

const CreateItemTransferPage: React.FC<Props> = ({ onSuccess }) => {
  const [createItemTransfer, { isSuccess, isLoading }] = usePostItemTransfersMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false)
        setShowSuccessMsg(true)
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: any = {
    ...createItemTransferModelInitial
  };



  const handleSubmit = async (values: MyFormValues) => {

    const transferItems: any[] = values.items.map(item => ({
      itemId: item.itemId,
      itemCode: item.itemCode,
      description: item.description,
      reasonForTransfer: item.reasonForTransfer,
      itemCost: item.itemCost,
      quantity: {
        quantityValue: typeof item.quantity.quantityValue === 'string'
          ? parseInt(item.quantity.quantityValue, 10) || 0
          : Math.floor(item.quantity.quantityValue),
        quantityUnit: item.quantity.quantityUnit,
      }
    }));

    const newItemTransfer: any = {
      items: transferItems,
      sourceLocationType: values.sourceLocationType,
      destinationLocationType: values.destinationLocationType,
    };

    if (values.sourceLocationType === 'inventoryLocation') {
      newItemTransfer.sourceLocation = {
        inventoryLocation: {
          locationId: values.sourceLocation.inventoryLocation?.locationId,
          locationCode: values.sourceLocation.inventoryLocation?.locationCode,
          description: values.sourceLocation.inventoryLocation?.description,
          developmentId: values.sourceLocation.inventoryLocation?.developmentId,
          developmentName: values.sourceLocation.inventoryLocation?.developmentName,
        }
      };
    } else if (values.sourceLocationType === 'supplier') {
      newItemTransfer.sourceLocation = {
        supplier: {
          id: values.sourceLocation.supplier?.id,
          name: values.sourceLocation.supplier?.name,
        }
      };
    } else if (values.sourceLocationType === 'unit') {
      newItemTransfer.sourceLocation = {
        development: {
          id: values.sourceLocation.development?.id,
          name: values.sourceLocation.development?.name,
        },
        project: {
          id: values.sourceLocation.project?.id,
          name: values.sourceLocation.project?.name,
        },
        unit: {
          id: values.sourceLocation.unit?.id,
          name: values.sourceLocation.unit?.name,
        }
      };
    }


    if (values.destinationLocationType === 'inventoryLocation') {
      newItemTransfer.destinationLocation = {
        inventoryLocation: {
          locationId: values.destinationLocation.inventoryLocation?.locationId,
          locationCode: values.destinationLocation?.inventoryLocation?.locationCode,
          description: values.destinationLocation?.inventoryLocation?.description,
          developmentId: values.destinationLocation?.inventoryLocation?.developmentId,
          developmentName: values.destinationLocation?.inventoryLocation?.developmentName,
        }
      };
    } else if (values.destinationLocationType === 'supplier') {
      newItemTransfer.destinationLocation = {
        supplier: {
          id: values.sourceLocation.supplier?.id,
          name: values.sourceLocation.supplier?.name,
        }
      };
    } else if (values.destinationLocationType === 'unit') {
      newItemTransfer.destinationLocation = {
        development: {
          id: values.sourceLocation.development?.id,
          name: values.sourceLocation.development?.name,
        },
        project: {
          id: values.sourceLocation.project?.id,
          name: values.sourceLocation.project?.name,
        },
        unit: {
          id: values.sourceLocation.unit?.id,
          name: values.sourceLocation.unit?.name,
        }
      };
    }

    setShowOverlay(true)
    try {
      const result = await createItemTransfer({ newItemTransfer }).unwrap();
      setShowOverlay(false);
      setShowOverlay(false);

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {

      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  }

  if (showSuccessMsg) {
    return (
      <Typography>Thank you, your response has been received</Typography>
    )
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}

      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <CreateItemTransferForm />
              <Button
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
    </React.Fragment>
  )
}

export default CreateItemTransferPage;
