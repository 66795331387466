import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import {Card, CardContent, CircularProgress, Grid, Paper, Stack, Typography} from "@mui/material";
import {KnowledgeBaseEntry, useGetKnowledgeBaseQuery} from "../../redux/vmsApi";
import DownloadIcon from '@mui/icons-material/Download';
import {styled} from "@mui/material/styles";
import config from "../../config";

const StyledCard = styled(Card)(({theme}) => ({
  display: 'flex',
  backgroundColor: '#1976d2',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: '0.3s',
  borderRadius: '30px',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)',
  },
}));

const StyledTypography = styled(Typography)({
  textAlign: 'center', color: '#fff',
});

const KnowledgeBase: React.FC = () => {
  const {data: knowledgeBaseEntries} = useGetKnowledgeBaseQuery();
  return (<PageWithHeader>
      <Paper elevation={2} sx={{margin: 10, padding: 5, borderRadius: '15px'}}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant='h1' gutterBottom sx={{fontWeight: 'bold', color: '#123456'}}>
              Knowledge Base
            </Typography>
          </Grid>
        </Grid>
        <Typography variant='body1' gutterBottom>
          Welcome to the Knowledge Base. Here, you'll find a collection of internal policies, guidelines, and important
          documents designed to help you navigate your role more effectively. Whether you’re looking for company
          procedures, compliance requirements, or best practices, this resource hub provides easy access to all the
          information you need.
        </Typography>
        <Grid container spacing={3}>
          {knowledgeBaseEntries ? knowledgeBaseEntries.map((entry: KnowledgeBaseEntry) => (
            <Grid item xs={12} key={entry.id}>
              <a
                href={`${config.CDN_URL}/${entry.documentPath}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{textDecoration: 'none', color: 'inherit', display: 'block'}}
              >
                <StyledCard>
                  <CardContent sx={{width: '100%'}}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                      <StyledTypography variant="h6" sx={{textAlign: 'left'}}>
                        {entry.documentName}
                      </StyledTypography>
                      <DownloadIcon sx={{fontSize: 25, color: '#fff'}}/>
                    </Stack>
                  </CardContent>
                </StyledCard>
              </a>
            </Grid>))
            :
            <CircularProgress sx={{margin: 10}}/>
          }
        </Grid>
      </Paper>
    </PageWithHeader>)
}

export default KnowledgeBase;