import React, { useState, useEffect } from 'react';

import PageWithHeader from '../../common/PageWithHeader';

import ProcurementProcessDashboard from '../dashboards/ProcurementProcessDashboard';
import RenderRequisitionHeader from '../common/renderRequisitionHeader';
import RenderRequisitionDetails from '../common/RenderRequisitionDetails';
import { Quotation, useGetDevelopmentsByIdQuery, useGetProcurementProcessesByIdQuery, useGetUsersMeQuery, usePutProcurementProcessesByIdMutation } from '../../../redux/vmsApi';
import config from "../../../config";

interface OwnProps {
  match?: any;
}
let updatedQuotationsAfterSelection: Quotation[];
const ProcurementProcessDetailsPage: React.FC<OwnProps> = ({ match }) => {
  const isInProcess:boolean=false;
  const { data: procurementProcess, isLoading, isError } = useGetProcurementProcessesByIdQuery({ id: match.params.uuid });
  const [updateProcurementProcess, { isSuccess, isLoading: isSubmitting }] = usePutProcurementProcessesByIdMutation();
  const { data: user } = useGetUsersMeQuery();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [showQuotationForm, setShowQuotationForm] = useState(false);

  const developmentId = procurementProcess?.requisition?.development.id;
  let procurementProcessPartial: any
  const { data: development } = useGetDevelopmentsByIdQuery(
    { id: developmentId! },
    { skip: !developmentId }
  );

  const [openDialog, setOpenDialog] = useState<string | null>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<number | null>(null);

  const handleDialogOpen = (path: string) => setOpenDialog(`${config.CDN_URL}/procurement/${path}`);
  const handleDialogClose = () => setOpenDialog(null);
  useEffect(() => {
    setShowOverlay(isSubmitting || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false);
        setShowSuccessMsg(true);
        setRedirectToDashboard(true);
      }, 3000);
    }
  }, [isSubmitting, isSuccess]);





  const handleSelectSupplier = async (index: number, quotation: any) => {
    const newSelection = selectedSupplier === index ? null : index;
    setSelectedSupplier(newSelection);

    if (newSelection !== null && procurementProcess?.currentTask === "CPOPicksSupplier") {
      const updatedQuotations = procurementProcess?.pcReview?.quotations?.map((q:any) => {
        if (q.supplier === quotation.supplier) {
          return { ...q, selected: true };
        }
        return q;
      });

      if (updatedQuotations) {
        updatedQuotationsAfterSelection = updatedQuotations;
      }

    }
  };

  if (redirectToDashboard) {
    return <ProcurementProcessDashboard />;
  }

  if (procurementProcess?.requisition && (!isLoading || !isError)) {
    return (
      <>

        <PageWithHeader>

          <RenderRequisitionHeader development={development || 'Development'} />
          {development && (
            <RenderRequisitionDetails
              procurementProcess={procurementProcess}
              development={development}
              selectedSupplier={selectedSupplier}
              openDialog={openDialog}
              handleDialogOpen={handleDialogOpen}
              handleDialogClose={handleDialogClose}
              handleSelectSupplier={handleSelectSupplier}
              showQuotationForm={showQuotationForm}
              setShowQuotationForm={setShowQuotationForm}
              isInProcess={isInProcess}
            />
          )}

        
        </PageWithHeader>

        
      </>
    );
  } else return <></>;

};

export default ProcurementProcessDetailsPage;
