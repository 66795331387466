import React from 'react';
import {Paper, Typography} from "@mui/material";

import ProcurementContainer from '../../procurement/containers/ProcurementContainer';
import CreateGrvComponent from '../component/CreateGrvComponent';
import GrvsList from '../lists/grvsList';


const GrvsPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Grvs
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateGrvComponent/>
      </Paper>
      <Paper sx={{my: 5}}>
        <GrvsList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default GrvsPage