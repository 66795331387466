import React, {useEffect, useState} from 'react';
import {Button, Stack, Typography} from "@mui/material";
import {Form, Formik} from 'formik';



import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {enGB} from 'date-fns/locale'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { InventoryLocation, NewInventoryLocation, usePostInventoryLocationsMutation } from '../../../redux/vmsApi';

import LoadingCover from '../../common/LoadingCover';
import CreateInventoryLocationForm from '../forms/CreateInventoryLocationForm';
import createInventoryLocationModel, { createInventoryLocationModelType } from '../models/createInventoryLocationModel';




let newInventoryLocation:NewInventoryLocation;
type MyFormValues = createInventoryLocationModelType

const createInventoryLocationModelInitial = createInventoryLocationModel.initialValues

type Props = {
  onSuccess?: (InventoryLocation: InventoryLocation) => void;
};

const CreateInventoryLocationPage: React.FC<Props> = ({onSuccess}) => {
  const [createInventoryLocation, {isSuccess, isLoading}] = usePostInventoryLocationsMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false)
        setShowSuccessMsg(true)
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createInventoryLocationModelInitial,
  };

  

  const handleSubmit = async (values: MyFormValues) => {
   
    const newInventoryLocation = {
        locationCode: values.locationCode,
        developmentId: values.developmentId,
        description: values.description,      
    }
   
    setShowOverlay(true)
    try {
      const result = await createInventoryLocation({newInventoryLocation}).unwrap();
      setShowOverlay(false);
      setShowOverlay(false);
      
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
     
      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  }

  if (showSuccessMsg) {
    return (
      <Typography>Thank you, your response has been received</Typography>
    )
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess}/>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <CreateInventoryLocationForm/>
              <Button
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
      </React.Fragment>
  )
}

export default CreateInventoryLocationPage;
