export const formatCurrency = (price: number = 0, currencyCode: string = 'usd') => {
 
  const locale = navigator.languages[0]
  try {
    return new Intl.NumberFormat(locale ?? 'en-US', {
      style: 'currency',
      currency: currencyCode,
    }).format(price)
  } catch(e) {
    return `${currencyCode}${price}`
  }
}