import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PageWithHeader from "../common/PageWithHeader";

interface VehicleLog {
  vehicleId: string;
  logs: {
    day: string;
    hours: number;
    site: string;
  }[];
}

const Log: React.FC = () => {
  const vehicles = [
    { id: "1", name: "Cat Roller" },
    { id: "2", name: "Bulldozer" },
    { id: "3", name: "Excavator" },
  ]; // Sample vehicle data

  const sites = ["Site A", "Site B", "Site C"]; // Sample site options

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const [selectedWeek, setSelectedWeek] = useState<string>("");
  const [selectedVehicle, setSelectedVehicle] = useState<string>("");
  const [vehicleLogs, setVehicleLogs] = useState<VehicleLog[]>([]);

  const handleLogChange = (
    vehicleId: string,
    day: string,
    field: "hours" | "site",
    value: number | string
  ) => {
    setVehicleLogs((prev) => {
      const updatedLogs = prev.map((vehicleLog) => {
        if (vehicleLog.vehicleId === vehicleId) {
          return {
            ...vehicleLog,
            logs: vehicleLog.logs.map((log) =>
              log.day === day ? { ...log, [field]: value } : log
            ),
          };
        }
        return vehicleLog;
      });

      // If the vehicle log doesn't exist, add it
      if (!updatedLogs.find((v) => v.vehicleId === vehicleId)) {
        updatedLogs.push({
          vehicleId,
          logs: daysOfWeek.map((day) => ({ day, hours: 0, site: "" })),
        });
      }

      return updatedLogs;
    });
  };

  const handleSubmit = () => {
    const logsForSelectedVehicle = vehicleLogs.find(
      (log) => log.vehicleId === selectedVehicle
    );
  };

  return (
    <PageWithHeader>
      <Box padding={3}>
        <Typography variant="h4" gutterBottom>
          Log Vehicle Hours
        </Typography>

        {/* Week Selector */}
        <Box mb={3}>
          <Typography variant="h6">Select Week</Typography>
          <TextField
            type="date"
            value={selectedWeek}
            onChange={(e) => setSelectedWeek(e.target.value)}
            fullWidth
          />
        </Box>

        {/* Vehicle Selector */}
        <Box mb={3}>
          <Typography variant="h6">Select Vehicle</Typography>
          <Select
            value={selectedVehicle}
            onChange={(e) => setSelectedVehicle(e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select a Vehicle
            </MenuItem>
            {vehicles.map((vehicle) => (
              <MenuItem key={vehicle.id} value={vehicle.id}>
                {vehicle.name}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {/* Form for Selected Vehicle */}
        {selectedVehicle && (
          <>
            <Typography variant="h6" mb={2}>
              Logging for:{" "}
              {vehicles.find((v) => v.id === selectedVehicle)?.name}
            </Typography>
            <Grid container spacing={2}>
              {daysOfWeek.map((day) => (
                <Grid item xs={12} sm={6} md={4} key={day}>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography>{day}</Typography>
                    <TextField
                      label="Hours"
                      type="number"
                      value={
                        vehicleLogs
                          .find((log) => log.vehicleId === selectedVehicle)
                          ?.logs.find((log) => log.day === day)?.hours || 0
                      }
                      onChange={(e) =>
                        handleLogChange(
                          selectedVehicle,
                          day,
                          "hours",
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                    <Select
                      displayEmpty
                      value={
                        vehicleLogs
                          .find((log) => log.vehicleId === selectedVehicle)
                          ?.logs.find((log) => log.day === day)?.site || ""
                      }
                      onChange={(e) =>
                        handleLogChange(selectedVehicle, day, "site", e.target.value)
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Site
                      </MenuItem>
                      {sites.map((site) => (
                        <MenuItem key={site} value={site}>
                          {site}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ marginTop: "16px" }}
            >
              Submit Logs
            </Button>
          </>
        )}
      </Box>
    </PageWithHeader>
  );
};

export default Log;
