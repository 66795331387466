import React, {useEffect, useState} from 'react';
import {Button, Stack, Typography} from "@mui/material";
import {Form, Formik} from 'formik';
import createStakeholderModel, {createStakeholderModelType} from '../forms/models/createStakeholderModel';
import { Address, ContactInfo,NewStakeholder, StakeholderPartial, usePostStakeholdersMutation} from '../../redux/vmsApi'
import LoadingCover from './LoadingCover'


import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {enGB} from 'date-fns/locale'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import CreateStakeholderForm from '../forms/CreateStakeholderForm';

let newStakeholder:NewStakeholder;
type MyFormValues = createStakeholderModelType

const createStakeholderModelInitial = createStakeholderModel.initialValues

type Props = {
  onSuccess?: (Stakeholder: StakeholderPartial) => void;
};

const CreateStakeholderPage: React.FC<Props> = ({onSuccess}) => {
  const [createStakeholder, {isSuccess, isLoading}] = usePostStakeholdersMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false)
        setShowSuccessMsg(true)
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createStakeholderModelInitial,
  };

  

  const handleSubmit = async (values: MyFormValues) => {
    const contactInfo: ContactInfo[] = values.contactInfo.map(item => ({
        email: item.email,
        phone: item.phone,
        contactPerson: item.contactPerson || 'defaultUnitValue',
    }));

    const address: Address[] = values.address.map(item => ({
        street: item.street,
        city: item.city,
        country: item.country || 'defaultUnitValue',
    }));
  
    
    
    const newStakeholder = {
      type: values.stakeholderType,
      name: values.stakeholderName,
      tin: values.tin,
      paymentTerms: values.paymentTerms,
      supplierCode: values.supplierCode,
      customerCode: values.customerCode,
      contactInfo: contactInfo,
      address: address
        
    }
   
    setShowOverlay(true)
    try {
      const result = await createStakeholder({newStakeholder}).unwrap();
      setShowOverlay(false);
      setShowOverlay(false);
      
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
     
      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  }

  if (showSuccessMsg) {
    return (
      <Typography>Thank you, your response has been received</Typography>
    )
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess}/>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <CreateStakeholderForm/>
              <Button
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
      </React.Fragment>
  )
}

export default CreateStakeholderPage;
