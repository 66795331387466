import React, { useEffect, useState } from 'react';
import { Form, FieldArray, Field } from 'formik';
import { Alert, Box, Button, Card, CardContent, CardHeader, Divider, FormControl, Grid, IconButton, MenuItem, Paper, Select, SelectChangeEvent, Stack, Switch, TextField, Typography } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import DropzoneArea from '../../common/DropzoneArea';
import { useGetQuotationsUploadProgressQuery, useGetStakeholdersByTypeByTypeQuery, usePostQuotationsUploadProgressMutation } from '../../../redux/vmsApi';

import { Delete as DeleteIcon } from '@mui/icons-material';
import ProcurementCalculator from '../common/procurementCalculator';
import CompetitiveAnalysis from '../common/competetiveAnalysis';
import { formatCurrency } from "../../../utils/currencyUtil";



const unitOptions = [
  { value: 'm' },
  { value: 'm²' },
  { value: 'm³' },
  { value: 'kg' },
  { value: 'lt' },
  { value: 'item' },
  { value: 'no unit' },
];

const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100;

const QuotationForm: React.FC<any> = ({ initialValues, handleSubmit, setFieldValue, values, showQuotationForm, setShowQuotationForm, procurementProcess }) => {
  const [showCalculator, setShowCalculator] = useState(false);

  const toggleCalculator = () => {
    setShowCalculator((prev) => !prev);
  };


  const [reqNumber, setReqNumber] = useState(procurementProcess?.requisition?.reqNumber || '');



  const { data: savedProgress, refetch } = useGetQuotationsUploadProgressQuery(
    { reqNumber: reqNumber }
  );



  const [saveQuotations] = usePostQuotationsUploadProgressMutation();
  const [feedback, setFeedback] = useState<{ message: string; type: "success" | "error" } | null>(null);



  useEffect(() => {
    if (reqNumber) {
      refetch();
    }
  }, [reqNumber, refetch]);


  const { data: stakeholders } = useGetStakeholdersByTypeByTypeQuery({ type: 'Supplier' });
  const handleApproval = async (isApproved: boolean) => {
    if (!values.comment && !showQuotationForm) {
      toast.error("Please provide a comment before proceeding.", {
        position: toast.POSITION.TOP_CENTER,
        theme: 'colored',
      });
      return;
    } else if (showQuotationForm && (values.quotations[0]?.attachments?.length === 0 || values.quotations.length === 0)) {
      toast.error("Please provide at least one quotation.", {
        position: toast.POSITION.TOP_CENTER,
        theme: 'colored',
      });
      return;
    }  else {
      values.approved = isApproved;
      handleSubmit(values);
    }
  };


  const handleSupplierChange = (event: SelectChangeEvent<any>, index: number) => {
    const supplierId = event.target.value;
    const supplierObject = stakeholders?.find((supplier: any) => supplier.id === supplierId); const supplierName = supplierObject ? supplierObject.name : '';

    const updatedQuotations = [...values.quotations];
    updatedQuotations[index] = {
      ...updatedQuotations[index],
      supplier: { id: supplierId, name: supplierObject?.name || '' },
      paymentTerms: supplierObject?.paymentTerms || '',
    };
    setFieldValue('quotations', updatedQuotations);
  };


  const calculateTotalWithoutDiscount = (items: any) => {
    return items.reduce((acc: number, item: any) => {
      const baseCost = item.quantity.quantityValue * item.rate;
      const VAT = item.VAT ? 0.15 * item.rate * item.quantity.quantityValue : 0;
      return acc + baseCost + VAT;
    }, 0);
  };


  const calculateTotalWithDiscount = (items: any, globalDiscount: number) => {
    const totalWithoutDiscount = calculateTotalWithoutDiscount(items);
    const totalCostSum = items.reduce((acc: number, item: any) => acc + (item.totalCostPerItem || 0), 0);
    return globalDiscount > 0 ? totalWithoutDiscount - globalDiscount : totalCostSum;
  };

  useEffect(() => {
    values.quotations.forEach((quotation: any, index: number) => {
      const totalVAT = quotation.quotationItems.reduce((acc: number, item: any) => {
        const VAT = item.VAT ? 0.15 * (item.rate - (item.discountPerItem || 0)) * item.quantity.quantityValue : 0;
        return acc + VAT;
      }, 0);
      const totalWithDiscount = calculateTotalWithDiscount(quotation.quotationItems, quotation.discount || 0);
      const totalb4VAT = totalWithDiscount - totalVAT;

      const totalDiscount = quotation.quotationItems.reduce((acc: number, item: any) => {
        const itemDiscount = (item.discountPerItem || 0) * (item.quantity.quantityValue || 0);
        return acc + itemDiscount;
      }, 0);
      setFieldValue(`quotations[${index}].totalVAT`, roundToTwoDecimals(totalVAT));
      setFieldValue(`quotations[${index}].totalVAT`, roundToTwoDecimals(totalVAT));
      setFieldValue(`quotations[${index}].totalb4VAT`, roundToTwoDecimals(totalb4VAT));
      setFieldValue(`quotations[${index}].grandTotal`, roundToTwoDecimals(totalWithDiscount));
      setFieldValue(`quotations[${index}].totalDiscount`, roundToTwoDecimals(totalDiscount));
    });
  }, [values.quotations, setFieldValue]);



  // useEffect(() => {
  //   if (procurementProcess?.requisition?.items) {
  //     setFieldValue(
  //       'quotations',
  //       values.quotations.map((quotation: any) => ({
  //         ...quotation,
  //         quotationItems: procurementProcess.requisition.items.map((item: any) => ({
  //           description: item.description,
  //           itemCode: item.itemCode,
  //           quantity: { quantityValue: item.quantity.quantityValue, quantityUnit: item.quantity.quantityUnit },
  //           rate: '',
  //           totalCostPerItem: (item.quantityValue || 0) * (item.rate || 0),
  //           VAT: false,
  //           procured: item.procured,
  //           unit: item.unit,
  //           discountPerItem: ''
  //         })),
  //       }))
  //     );
  //   }
  // }, [procurementProcess?.requisition?.items, setFieldValue]);


  // useEffect(() => {
  //   if (procurementProcess?.requisition?.items) {
  //     setFieldValue(
  //       'quotations',
  //       values.quotations.map((quotation: any) => ({
  //         ...quotation,
  //         quotationItems: procurementProcess.requisition.items.map((item: any) => ({
  //           description: item.description,
  //           itemCode: item.itemCode,
  //           quantity: { quantityValue: item.quantity.quantityValue, quantityUnit: item.quantity.quantityUnit },
  //           rate: '',
  //           totalCostPerItem: (item.quantityValue || 0) * (item.rate || 0),
  //           VAT: false,
  //           procured: item.procured,
  //           unit: item.unit,
  //           discountPerItem: ''
  //         })),
  //       }))
  //     );
  //   }
  // }, [procurementProcess?.requisition?.items, setFieldValue]);


  useEffect(() => {
    if (procurementProcess.pcReview && Array.isArray(procurementProcess.pcReview.quotations) && procurementProcess.pcReview.quotations.length > 0) {
      setFieldValue('quotations',procurementProcess.pcReview.quotations);
    } else if (Array.isArray(savedProgress) && savedProgress.length > 0) {
      setFieldValue('quotations', savedProgress[0].quotations);
    } else if (Array.isArray(procurementProcess?.requisition?.items)) {
      setFieldValue(
        'quotations',
        values.quotations.map((quotation: any) => ({
          ...quotation,
          quotationItems: procurementProcess.requisition.items.map((item: any) => ({
            description: item.description,
            itemCode: item.itemCode,
            quantity: {
              quantityValue: item.quantity?.quantityValue || '',
              quantityUnit: item.quantity?.quantityUnit || '',
            },
            rate: '',
            totalCostPerItem: (item.quantity?.quantityValue || 0) * (item.rate || 0),
            VAT: false,
            procured: item.procured || false,
            unit: item.unit || { id: '', name: '' },
            discountPerItem: '',
          })),
        }))
      );
    }
  }, [savedProgress, procurementProcess?.requisition?.items, setFieldValue]);



  const handleSaveQuotations = async () => {
    const payload = {
      reqNumber,
      quotations: values.quotations,
    };

    try {
      await saveQuotations({ newQuotationsUploadProgress: payload });

      setFeedback({ message: "Quotations progress saved successfully!", type: "success" });
      refetch();
    } catch (error) {

      setFeedback({ message: "Error saving quotations progress.", type: "error" });
    }
  };

  return (

    <Form>

      {showQuotationForm && (
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2, maxWidth: "80%", mx: "auto" }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Retrieve or Save Quotations
          </Typography>

          <Stack spacing={2}>
            <TextField
              label="Enter Req Number"
              variant="outlined"
              fullWidth
              value={reqNumber}
              onChange={(e) => {
                setReqNumber(e.target.value)
               
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => refetch()}
              disabled={!reqNumber}
            >
              Fetch Quotations
            </Button>

            {Array.isArray(savedProgress) && savedProgress.length > 0 ? (
              <Alert severity="success">Quotations progress retrieved.</Alert>
            ) : (
              <Alert severity="warning">No saved progress found.</Alert>
            )}

            {feedback && <Alert severity={feedback.type}>{feedback.message}</Alert>}

            <Button
              variant="contained"
              type="button"
              color="success"
              fullWidth
              onClick={handleSaveQuotations}
            >
              Save Quotations
            </Button>
          </Stack>
        </Paper>
      )}


      <ToastContainer />
      <Stack spacing={4} width="100%" p={10} pb={5}>
        {!showQuotationForm && (
          <>

            <TextField
              name="comment"
              label="Comments"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              onChange={(e) => setFieldValue("comment", e.target.value)}
            />


            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <ConfirmationDialog
                  actionType="Approve"

                  onConfirm={() => handleApproval(true)}
                />
                <ConfirmationDialog
                  actionType="Reject"

                  onConfirm={() => handleApproval(false)}
                />
              </Stack>
            </Stack>
          </>
        )}        {showQuotationForm && (
          <FieldArray name="quotations">
            {({ push: pushQuotation, remove: removeQuotation }) => (
              <Stack spacing={3}>
                {values.quotations.map((quotation: any, qIndex: number) => (
                  <Card key={qIndex} sx={{ margin: 2, padding: 2, backgroundColor: '#f5f5f5', borderRadius: 4, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}>
                    <CardHeader
                      title={<Typography variant="h6" sx={{ color: '#1976d2', fontWeight: 'bold' }}>Supplier({qIndex + 1})</Typography>}
                      action={<IconButton onClick={() => removeQuotation(qIndex)} color="error"><RemoveCircleOutline /></IconButton>}
                    />
                    <Divider sx={{ marginY: 2 }} />
                    <CardContent>
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12} md={4}>
                          <Typography variant="subtitle1" gutterBottom>Supplier</Typography>
                          <Select
                            name={`quotations.${qIndex}.supplier`}
                            fullWidth
                            value={quotation.supplier?.id || ''}
                            onChange={(e) => handleSupplierChange(e, qIndex)}
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                          >
                            {stakeholders?.map((stakeholder: any) => (
                              <MenuItem key={stakeholder.id} value={stakeholder.id}>{stakeholder.name}</MenuItem>
                            ))}
                          </Select>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                            Payment Terms
                          </Typography>
                          <TextField
                            name={'quotations.${qIndex}.paymentTerms'}
                            fullWidth
                            variant="outlined"
                            value={quotation.paymentTerms}
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                          />

                        </Grid>


                        <Grid item xs={12} md={4}>
                          <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                            Quotation Number
                          </Typography>
                          <TextField
                            name={`quotations.${qIndex}.quotationNumber`}
                            fullWidth
                            variant="outlined"
                            value={quotation.quotationNumber}
                            onChange={(e) => setFieldValue(`quotations.${qIndex}.quotationNumber`, e.target.value)}
                            placeholder="Enter Quotation Number"
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                          />

                        </Grid>



                        <Field name={`quotations.${qIndex}.totalVAT`} type="hidden" />
                        <Field name={`quotations.${qIndex}.totalDiscount`} type="hidden" />
                        <Field name={`quotations.${qIndex}.totalb4VAT`} type="hidden" />
                        <Field name={`quotations.${qIndex}.grandTotal`} type="hidden" />

                      </Grid>

                      <Grid item xs={12} md={4}>

                        <Grid container spacing={2} alignItems="center">
                         
                          <Grid item xs={9}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                              Remarks
                            </Typography>
                            <TextField
                              name={`quotations.${qIndex}.remarks`}
                              fullWidth
                              variant="outlined"
                              value={quotation.remarks}
                              onChange={(e) => setFieldValue(`quotations.${qIndex}.remarks`, e.target.value)}
                              placeholder="Enter remarks"
                              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                            />
                          </Grid>


                          <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                              Currency
                            </Typography>
                            <FormControl fullWidth variant="outlined" sx={{ backgroundColor: '#fff', borderRadius: 1 }}>
                              <Select
                                name={`quotations.${qIndex}.currency`}
                                value={quotation.currency || 'USD'}
                                onChange={(e) => setFieldValue(`quotations.${qIndex}.currency`, e.target.value)}
                              >
                                <MenuItem value="USD">USD</MenuItem>
                                <MenuItem value="EURO">EURO</MenuItem>
                                <MenuItem value="ZIG">ZIG</MenuItem>
                                <MenuItem value="ZAR">ZAR</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>


                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                          Attachments
                        </Typography>
                        <DropzoneArea
                          setFieldValue={setFieldValue}
                          fieldName={`quotations.${qIndex}.attachments`}
                          attachments={quotation.attachments || []}
                        />
                      </Grid>
                      <br></br>
                      <div>

                        <Button
                          variant="contained"
                          onClick={toggleCalculator}
                          sx={{
                            marginBottom: 2,
                            backgroundColor: "gold",
                            color: "black",
                            "&:hover": {
                              backgroundColor: "yellow",
                            },
                          }}
                        >
                          {showCalculator ? "Hide Procurement Calculator" : "Use Procurement Calculator"}
                        </Button>


                        {showCalculator && (
                          <>
                            <div>
                              <ProcurementCalculator />
                            </div>
                            <br />
                          </>
                        )}
                      </div>
                      <br></br>
                      <FieldArray name={`quotations.${qIndex}.quotationItems`}>
                        {({ push: pushItem, remove: removeItem }) => (
                          <Stack spacing={2} mt={3}>
                            {quotation.quotationItems.map((item: any, iIndex: number) => (
                              <Stack direction="row" spacing={2} alignItems="center" key={iIndex}>
                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.description`}
                                  label="Description"
                                  fullWidth
                                  value={item.description}
                                  onChange={(e) => setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.description`, e.target.value)}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />
                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.quantity.quantityValue`}
                                  label="Quantity"
                                  type="number"
                                  value={item.quantity.quantityValue}
                                  onChange={(e) => {
                                    const quantityValue = parseFloat(e.target.value) || 0;
                                    const baseCost = (item.rate - item.discountPerItem) * quantityValue;
                                    const VAT = item.VAT ? 0.15 * (item.rate - item.discountPerItem) * quantityValue : 0;
                                    const totalCost = baseCost + VAT;

                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.quantity.quantityValue`, e.target.value);
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`, roundToTwoDecimals(totalCost));
                                  }}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />
                                <Select
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.quantity.quantityUnit`}
                                  value={item.quantity.quantityUnit || ''}
                                  onChange={(e) => setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.quantity.quantityUnit`, e.target.value)}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                >
                                  {unitOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{option.value}</MenuItem>
                                  ))}
                                </Select>

                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.rate`}
                                  label="Rate"
                                  type="number"
                                  value={item.rate}
                                  onChange={(e) => {
                                    const rate = parseFloat(e.target.value) || 0;
                                    const baseCost = (rate - item.discountPerItem) * item.quantity.quantityValue;
                                    const VAT = item.VAT ? 0.15 * (item.rate - item.discountPerItem) * item.quantity.quantityValue : 0;
                                    
                                    const totalCost = baseCost + VAT;

                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.rate`, rate);
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`, totalCost);
                                  }}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />






                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.discountPerItem`}
                                  label="Discount per Item"
                                  type="number"
                                  value={item.discountPerItem}
                                  onChange={(e) => {
                                    const discountPerItem = parseFloat(e.target.value) || 0;
                                    const baseCost = (item.rate - discountPerItem) * item.quantity.quantityValue;
                                    const VAT = item.VAT ? 0.15 * (item.rate - item.discountPerItem) : 0;
                                    const totalCost = baseCost + VAT;
                                   
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.discountPerItem`, discountPerItem);
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`, roundToTwoDecimals(totalCost));


                                    if (discountPerItem > 0) {
                                      setFieldValue(`quotations.${qIndex}.discount`, 0);
                                    }
                                  }}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                  disabled={quotation.discount > 0}
                                />

                                <Switch
                                  checked={item.VAT || false}
                                  onChange={(e) => {
                                    const VATEnabled = e.target.checked;
                                    const baseCost = (item.rate - item.discountPerItem) * item.quantity.quantityValue;
                                    const VAT = VATEnabled ? 0.15 * (item.rate - item.discountPerItem) * item.quantity.quantityValue : 0;
                                    const totalCost = baseCost + VAT;

                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.VAT`, VATEnabled);
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`,roundToTwoDecimals( totalCost));
                                  }}
                                />
                                <Typography>VAT: {item.VAT ? "Yes (15%)" : "No"}</Typography>

                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`}
                                  label="Total Amount"
                                  value={formatCurrency(item.totalCostPerItem || 0,quotation.currency||'usd')}
                                  disabled
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />


                                <IconButton color="error" onClick={() => removeItem(iIndex)}><DeleteIcon /></IconButton>
                              </Stack>
                            ))}
                            <Button
                              onClick={() => pushItem({ description: '', quantity: { quantityValue: 0, quantityUnit: '' }, rate: 0, totalCostPerItem: 0,VAT:false,unit:{id:'',name:''},procured:false })}
                              variant="contained"
                              size="small"
                            >
                              Add Item
                            </Button>
                          </Stack>
                        )}
                      </FieldArray>
                    </CardContent>
                    <Card sx={{ padding: '15px', backgroundColor: '#d1e7dd', textAlign: 'right', borderRadius: 2 }}>
                      <Stack direction="column" spacing={1}>



                        {/* this includes VAT but excludes discount  */}
                        {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            Total (Excl. Discount):
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {formatCurrency(calculateTotalWithoutDiscount(quotation.quotationItems))}
                          </Typography>
                        </Box> */}

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            Total (Excl. VAT):
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {formatCurrency(
                              calculateTotalWithDiscount(quotation.quotationItems, quotation.discount || 0) -
                              quotation.quotationItems.reduce((acc: number, item: any) => {
                                const VAT = item.VAT ? 0.15 * (item.rate - item.discountPerItem) * item.quantity.quantityValue : 0;
                                return acc + VAT;
                              }, 0),quotation.currency||'usd'
                            )}
                          </Typography>
                        </Box>

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            VAT(15%):
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {formatCurrency(quotation.totalVAT || 0,quotation.currency||'usd')}
                          </Typography>

                        </Box>

                        {/* So this is our total including VAT and discount (Incl. Discount) that we will use for PO */}
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            Grand Total :
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {formatCurrency(calculateTotalWithDiscount(quotation.quotationItems, quotation.discount || 0),quotation.currency||'usd')}
                          </Typography>
                        </Box>
                      </Stack>
                    </Card>

                  </Card>
                ))}
                <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={() => pushQuotation({ supplier: '', paymentTerms: '', remarks: '',currency:'',quotationNumber:'',attachments: [], quotationItems: procurementProcess.requisition.items })}>Add Quotation</Button>
              </Stack>
            )}

          </FieldArray>

        )}

        {showQuotationForm && (
          <CompetitiveAnalysis quotations={values.quotations} />)}
        <ConfirmationDialog
          actionType="Submit Info"

          onConfirm={() => handleApproval(true)}
        />
        <Button color="primary" variant="outlined" onClick={() => setShowQuotationForm(false)}>Go back Dashboard</Button>

      </Stack>
    </Form>


  );
};

export default QuotationForm;
