import React, { SyntheticEvent } from 'react';
import { SnackbarCloseReason, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { theme } from './theme/theme';
import store, { history } from './redux/store';
import { Provider } from 'react-redux';
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppDispatch, useAppSelector, useAuth } from "./redux/hooks";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router/immutable";
import LoginModal from './components/authentication/LoginModal';
import Notification from "./components/common/Notification";
import ui from "./redux/reducers/uiReducer";
import VmsError from "./components/pages/VmsError";
import VisitorManagementDashboard from "./components/pages/VisitorManagementDashboard";
import { useGetUsersMeQuery } from "./redux/vmsApi";
import LoadingCover from "./components/common/LoadingCover";
import Scan from "./components/pages/Scan";
import CssBaseline from '@mui/material/CssBaseline';

import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';  

// @ts-ignore
import HttpsRedirect from 'react-https-redirect';
import ProcurementRoutes from "./components/procurement/ProcurementRoutes";
import EmployeeReviewRoutes from "./components/reviews/EmployeeReviewRoutes";
import Dashboard from "./components/pages/Dashboard";
import PrivateRoute from './components/common/PrivateRoute';
import PropertyRegisterRoutes from "./components/propertyRegister/PropertyRegisterRoutes";
import AccessControlRoutes from "./components/accesscontrol/AccessControlRoutes";
import PayrollGenerator from "./components/payroll/PayrollGenerator";
import InventoryRoutes from './components/inventory/InventoryRoutes';
import PlantReportsRoutes from "./components/plantReports/PlantReportsRoutes";
import AgreementsRoutes from "./components/agreements/AgreementsRoutes";
import KnowledgeBase from "./components/knowledgeBase/KnowledgeBase";

const App = () => {
  const userToken = document.cookie.match('(^| )jwtToken=([^;].+?)(;|$)');
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const actionNotification = useAppSelector(state => state.ui.actionNotification);
  const closeNotification = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway' || reason === 'timeout') {
      dispatch(ui.actions.removeActionNotification());
    }
  };

  const shouldSkip = (userToken && userToken[2] && !user) ? (() => { })() : skipToken;
  const { isLoading } = useGetUsersMeQuery(shouldSkip);
  if (isLoading) return <LoadingCover />;

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <VmsError className={`App`}>
          <ConnectedRouter history={history}>

            {actionNotification && (
              <Notification
                open={actionNotification.show}
                title={actionNotification.notification.title}
                message={actionNotification.notification.description}
                onClose={closeNotification}
                position={{ vertical: 'bottom', horizontal: 'left' }}
              />
            )}

           
            <ToastContainer />

            <Switch>
              <Route exact path="/" component={Dashboard} />
              <PrivateRoute exact path="/invite" component={VisitorManagementDashboard} roles={['vms']} />
              <PrivateRoute exact path="/invite/scan" component={Scan} roles={['vms']} />
              <PrivateRoute path="/reviews" component={EmployeeReviewRoutes} roles={['user']} />
              <PrivateRoute path="/agreements" component={AgreementsRoutes} roles={['agreement']} />
              <PrivateRoute path="/procurement" component={ProcurementRoutes} roles={['procurement']} />
              <PrivateRoute path="/inventory" component={InventoryRoutes} roles={['procurement']} />
              <PrivateRoute path="/property-register" component={PropertyRegisterRoutes} roles={['propertyRegister']} />
              <PrivateRoute path="/access-control" component={AccessControlRoutes} roles={['user']} />
              <PrivateRoute path="/payroll" component={PayrollGenerator} roles={['payroll']} />
              <PrivateRoute path="/plant-reports" component={PlantReportsRoutes} roles={['admin']} />
              <PrivateRoute path="/knowledge-base" component={KnowledgeBase} roles={['user']} />

            </Switch>
          </ConnectedRouter>
          <LoginModal />
        </VmsError>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

const Wrapper = () => {
  return (
    <Provider store={store}>
      <CssBaseline />
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    </Provider>
  );
};

export default Wrapper;
