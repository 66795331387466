import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from "@mui/material";
import { Form, Formik } from 'formik';



import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import LoadingCover from '../../common/LoadingCover';
import { GrvPartial, NewGrv, usePostCreateGrvMutation } from '../../../redux/vmsApi';
import createGrvModel, { createGrvModelType } from '../models/createGrvModel';
import CreateGrvForm from '../forms/CreateGrvForm';




let newGrv: NewGrv;
type MyFormValues = createGrvModelType

const createGrvModelInitial = createGrvModel.initialValues

type Props = {
  onSuccess?: (Grv: GrvPartial) => void;
};

const CreateGrvPage: React.FC<Props> = ({ onSuccess }) => {
  const [createGrv, { isSuccess, isLoading }] = usePostCreateGrvMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false)
        setShowSuccessMsg(true)
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createGrvModelInitial,
  };



  const handleSubmit = async (values: MyFormValues) => {

    const newGrv = {
      purchaseOrderId: values.purchaseOrderId,
      status: values.status,  
    }
    setShowOverlay(true)
    try {
      const result = await createGrv({ newGrv }).unwrap();
      setShowOverlay(false);
      setShowOverlay(false);

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {

      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  }

  if (showSuccessMsg) {
    return (
      <Typography>Thank you, your response has been received</Typography>
    )
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}

      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <CreateGrvForm />
              <Button
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
    </React.Fragment>
  )
}

export default CreateGrvPage;
