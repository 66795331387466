import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {formatCurrency} from "../../../utils/currencyUtil";

const ComparativeAnalysis = ({ quotations, user }: { quotations: any[]; user: any }) => {
  const analysisData: any = {};

  interface Item {
    currency:any
    supplier: string;
    rate: number;
    discount: number;
    totalCost: number;
    quantity: number;
  }
  
  quotations.forEach((quotation) => {
    quotation.quotationItems?.forEach((item: any) => {
      const { description, rate, totalCostPerItem, discountPerItem, quantity } = item;
      const itemData = {
        currency:quotation.currency,
        supplier: quotation.supplier.name,
        rate: parseFloat(rate) || 0,
        discount: parseFloat(discountPerItem) || 0,
        totalCost: parseFloat(totalCostPerItem) || 0,
        quantity: quantity?.quantityValue || 0,
      };

      if (!analysisData[description]) {
        analysisData[description] = [];
      }
      
      analysisData[description].push(itemData);
    });
  });

 
  const supplierProvidesAllItems = (supplierName: string) => {
    return Object.values(analysisData as Record<string, Item[]>).every((items) =>
      items.some((item) => item.supplier === supplierName && item.totalCost > 0)
    );
  };
  
  const getBestAndWorstSuppliers = (suppliers: any[]) => {
    const totalCosts = suppliers.map((s: any) => s.totalCost);
    const minCost = Math.min(...totalCosts);
    const maxCost = Math.max(...totalCosts);

    return {
      best: suppliers.find((s: any) => s.totalCost === minCost)?.supplier,
      worst: suppliers.find((s: any) => s.totalCost === maxCost)?.supplier,
      minCost,
      maxCost,
    };
  };

 
  const supplierSummary = quotations.map((q) => ({
    currency:q.currency,
    supplier: q.supplier.name,
    totalBeforeVAT: q.totalb4VAT,
    totalVAT: q.totalVAT,
    grandTotal: q.grandTotal,
    totalDiscount: q.totalDiscount,
    providesAllItems: supplierProvidesAllItems(q.supplier.name),
  }));

  
  const recommendedSupplier = supplierSummary
  .filter((s) => s.providesAllItems) 
  .reduce(
    (prev, curr) =>
      prev === null || curr.grandTotal < prev.grandTotal ? curr : prev,
    null as { 
      currency:any;
      supplier: any; 
      totalBeforeVAT: any; 
      totalVAT: any; 
      grandTotal: any; 
      totalDiscount: any; 
      providesAllItems: boolean; 
    } | null
  );


  return (
    <Box sx={{ padding: 3, backgroundColor: "#f5f5f5" }}>
    
      <Card
        sx={{
          marginBottom: 3,
          borderRadius: 2,
          boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#1976d2" }}
          >
            Procurement Officer Details
          </Typography>
          <Typography variant="body1">
            <strong>Name:</strong> {user.name}
          </Typography>
          {/* <Typography variant="body1">
            <strong>Job Title:</strong> {user.jobTitle}
          </Typography> */}
          <Typography variant="body1">
            <strong>Department:</strong> {user.department}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> {user.email}
          </Typography>
        </CardContent>
      </Card>

     
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#1976d2", marginBottom: 2 }}
      >
        Comparative Analysis
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#1976d2" }}>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
              >{`Item Description (Quantity)`}</TableCell>
              {quotations.map((q, index) => (
                <TableCell
                  key={index}
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {q.supplier.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(analysisData).map(([description, suppliers]: any) => {
              const { minCost, maxCost } = getBestAndWorstSuppliers(suppliers);

              return (
                <TableRow key={description}>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {description}{" "}
                    {suppliers[0]?.quantity && (
                      <span>({suppliers[0].quantity})</span>
                    )}
                  </TableCell>
                  {suppliers.map((s: any, index: number) => {
                    const backgroundColor =
                      s.totalCost === 0
                        ? "#FFEB3B"
                        : s.totalCost === minCost
                        ? "lightgreen"
                        : s.totalCost === maxCost
                        ? "lightcoral"
                        : "rgba(255, 193, 7, 0.2)"; 

                    return (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          backgroundColor,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {s.totalCost === 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <WarningAmberIcon sx={{ marginRight: 1 }} />
                            Out of stock
                          </Box>
                        ) : (
                          `${formatCurrency(s.totalCost,s.currency||'usd')}`
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

     
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", color: "#1976d2", marginTop: 3 }}
      >
        Supplier Summary
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
          marginTop: 2,
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#1976d2" }}>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Supplier
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Total Before VAT
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Total VAT
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Grand Total
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Total Discount
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Provides All Items
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplierSummary.map((s, index) => (
              <TableRow key={index}>
                <TableCell>{s.supplier}</TableCell>
                <TableCell>{formatCurrency(s.totalBeforeVAT,s.currency||'usd')}</TableCell>
                <TableCell>{formatCurrency(s.totalVAT,s.currency||'usd')}</TableCell>
                <TableCell>{formatCurrency(s.grandTotal,s.currency||'usd')}</TableCell>
                <TableCell>{formatCurrency(s.totalDiscount,s.currency||'usd')}</TableCell>
                <TableCell>
                  {s.providesAllItems ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      
      <Box sx={{ marginTop: 3 }}>
        {recommendedSupplier ? (
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "green" }}
          >
            System Recommended Supplier: {recommendedSupplier.supplier} (All Items Provided, Least Grand Total)
          </Typography>
        ) : (
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "red" }}
          >
            No supplier provides all required items.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ComparativeAnalysis;
 
