import React, { useState } from 'react';
import {
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import PageWithHeader from "../common/PageWithHeader";
import { useAppSelector } from "../../redux/hooks";
import { useGetLifeWheelsByUserByUserIdQuery } from "../../redux/vmsApi";
import LifeWheelVisualisation from './LifeWheelVisualisation';
import LoadingCover from "../common/LoadingCover";

const HistoricLifeWheel: React.FC = () => {
  const currentUser = useAppSelector(state => state.auth.user);
  const { data: historicLifeWheels, isLoading, error } = useGetLifeWheelsByUserByUserIdQuery({ userId: currentUser?.id ?? '' });
  const [selectedMonth, setSelectedMonth] = useState<string>('');

  if (isLoading) return <LoadingCover/>;
  if (error) return <div>Error loading data</div>;

  const handleMonthChange = (event: SelectChangeEvent) => {
    setSelectedMonth(event.target.value);
  };

  const selectedLifeWheel = historicLifeWheels?.find(lw => lw.reviewPeriod === selectedMonth);

  return (
    <PageWithHeader>
      <Stack spacing={4} width="100%" p={10} pb={5}>
        <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
          Your Historic Life Wheel Submissions
        </Typography>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Review Period</InputLabel>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            label="Review Period"
          >
            {historicLifeWheels?.map((lifeWheel) => (
              <MenuItem key={lifeWheel.reviewPeriod} value={lifeWheel.reviewPeriod}>
                {lifeWheel.reviewPeriod}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedLifeWheel && (
          <div>
            <Typography variant="h5" gutterBottom>
              Life Wheel for {selectedMonth}
            </Typography>
            <div style={{ maxWidth: '600px', margin: '0 auto' }}>
              <LifeWheelVisualisation data={selectedLifeWheel.responses[0]} />
            </div>
          </div>
        )}
      </Stack>
    </PageWithHeader>
  );
}

export default HistoricLifeWheel;
