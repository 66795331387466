import { usePostGetSignedUrlMutation } from "../redux/vmsApi";
import React from "react";
import axios from "axios";

export const uploadFileToS3 = async (
  file: File,
  getSignedUrl: ReturnType<typeof usePostGetSignedUrlMutation>[0],
  setUploadProgress: React.Dispatch<React.SetStateAction<number | null>>,
  useRandomId: boolean = false,
  customFolder: string = '',
): Promise<{ name: string; url: string }> => {
  try {
    const fileName = useRandomId ? `${crypto.randomUUID()}_${file.name}` : file.name;

    const path = customFolder ? `${customFolder}/${fileName}` : fileName;

    const response = await getSignedUrl({
      body: {
        fileName: path,
        fileType: file.type,
      },
    }).unwrap();

    const signedUrl = response.url ?? '';

    await axios.put(signedUrl, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        }
      },
    });

    const url = signedUrl.split('?')[0];
    return { name: path, url };
  } catch (error) {
    console.error('Error uploading file to S3:', error);
    throw error;
  }
};
