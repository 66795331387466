import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  useGetDevelopmentsProjectsQuery,
  useGetItemsQuery,
  useGetUnitsByProjectByProjectIdQuery
} from '../../../redux/vmsApi';
import billOfMaterialFormModel from '../../forms/models/createBillOfMaterialModel';
import {formatCurrency} from "../../../utils/currencyUtil";


const {
  formField: {
    project,
    unitId,
    sectionNumber,
    sectionTitle,
    description,
    quantity,
    quantityUnit,
    rate,
    itemCode
  }
} = billOfMaterialFormModel;

const quantityUnits = [
  'kg', 'm', 'pcs', 'lt', 'm²', 'm³', 'roll', 'bag', 'length', 'No', 'sheets'
];

interface OwnProps {
  editing?: boolean;
}

const CreateBillOfMaterialsForm: React.FC<OwnProps> = (props) => {
  const { editing = false } = props;
  const { errors, touched, values, setFieldValue } = useFormikContext<any>();
  const { data: items } = useGetItemsQuery({});
  const { data: projects = [] } = useGetDevelopmentsProjectsQuery();

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const { data: units = [] } = useGetUnitsByProjectByProjectIdQuery(
    { projectId: selectedProjectId ?? '' },
    { skip: !selectedProjectId }
  );

  useEffect(() => {
    values.sections.forEach((section: any, sectionIndex: number) => {
      const generatedSectionNo = `SECTION-${sectionIndex + 1}`;
      setFieldValue(`sections[${sectionIndex}].sectionNumber`, generatedSectionNo);
      section.items.forEach((item: any, itemIndex: number) => {
        const itemId = `${sectionIndex + 1}.${itemIndex + 1}`;
        setFieldValue(`sections[${sectionIndex}].items[${itemIndex}].itemId`, itemId);
      });
    });
  }, [values, setFieldValue]);

  const handleProjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProjectId(e.target.value);
  };

  const handleAutocompleteChange = (
    newValue: { itemCode: string; description: string; quantityUnit: string|undefined; } | null, 
    index: number, itemIndex: number
  ) => {
    if (newValue) {
      setFieldValue(`sections[${index}].items[${itemIndex}].itemCode`, newValue.itemCode);
      setFieldValue(`sections[${index}].items[${itemIndex}].itemCode`, newValue.description);
      setFieldValue(`sections[${index}].items[${itemIndex}].quantityUnit`, newValue.quantityUnit);
    } else {
      setFieldValue(`sections[${index}].items[${itemIndex}].itemCode`, '');
      setFieldValue(`sections[${index}].items[${itemIndex}].itemCode`, '');
      setFieldValue(`sections[${index}].items[${itemIndex}].quantityUnit`, '');
    }
  
  };

  return (
    <Stack width="100%" spacing={5}>
      <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        {editing ? 'Edit' : 'Add'} Bill of Material
      </Typography>
      <FieldArray
        name="sections"
        render={arrayHelpers => (
          <React.Fragment>
            <Stack direction={'row'} spacing={2}>
              <Field
                as={TextField}
                select
                onChange={handleProjectChange}
                {...project}
                error={touched[project.name] && Boolean(errors[project.name])}
                helperText={touched[project.name] && errors[project.name] ? errors[project.name] : ''}
                fullWidth
              >
                {projects.map(project => (
                  <MenuItem key={project.projectId} value={project.projectId}>
                    {project.name}
                  </MenuItem>
                ))}
              </Field>
              <Field
                as={TextField}
                select
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(unitId.name, e.target.value)}
                {...unitId}
                error={touched[unitId.name] && Boolean(errors[unitId.name])}
                helperText={touched[unitId.name] && errors[unitId.name] ? errors[unitId.name] : ''}
                fullWidth
              >
                {units.map(unit => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.unitName}
                  </MenuItem>
                ))}
              </Field>
            </Stack>

            {values.sections.map((section: any, index: number) => (
              <Stack key={index} direction="column" spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <TextField
                    {...sectionNumber}
                    value={`SECTION-${index + 1}`}
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                  <TextField
                    {...sectionTitle}
                    value={section.sectionTitle}
                    variant="outlined"
                    fullWidth
                    onChange={e => setFieldValue(`sections[${index}].sectionTitle`, e.target.value)}
                  />
                  <IconButton onClick={() => arrayHelpers.remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>

                <FieldArray
                  name={`sections[${index}].items`}
                  render={itemHelpers => (
                    <React.Fragment>
                      {section.items.map((item: any, itemIndex: number) => (
                        <Stack key={itemIndex} direction="row" spacing={2} alignItems="center">
                          <TextField
                            label="Item ID"
                            value={`${index + 1}.${itemIndex + 1}`}
                            variant="outlined"
         
                            disabled
                          />
                          <Autocomplete
                            options={items ?? []}  
                            fullWidth
                            getOptionLabel={(option) => option.description || ''} 
                            onChange={(event, newValue) => handleAutocompleteChange(newValue, index, itemIndex)}
                            style={{ width: '600px' }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Item"
                                variant="outlined"
                                error={touched.selectedBomItem && Boolean(errors.selectedBomItem)}
                                helperText={touched.selectedBomItem ? errors.selectedBomItem : ''}
                                
                                />
                            )}
                          />
                          <Field {...itemCode} type="hidden" />
                          <Field  {...description} type="hidden" />
                          {/* <TextField
                            {...description}
                            value={item.description}
                            variant="outlined"
                            onChange={e => setFieldValue(`sections[${index}].items[${itemIndex}].description`, e.target.value)}
                          /> */}
                          <TextField
                            {...quantity}
                            value={item.quantity}
                            variant="outlined"
                            onChange={e => {
                              const quantityValue = Number(e.target.value);
                              setFieldValue(`sections[${index}].items[${itemIndex}].quantity`, quantityValue);
                            }}
                          />
                          <TextField
                            select
                            {...quantityUnit}
                            value={item.quantityUnit}
                            variant="outlined"
                           
                            onChange={e => setFieldValue(`sections[${index}].items[${itemIndex}].quantityUnit`, e.target.value)}
                          >
                            {quantityUnits.map(unit => (
                              <MenuItem key={unit} value={unit}>
                                {unit}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            {...rate}
                            type="number"
                            value={item.rate}
                            variant="outlined"
                            onChange={e => {
                              const monetaryRateValue = parseFloat(e.target.value);
                              setFieldValue(`sections[${index}].items[${itemIndex}].rate`, monetaryRateValue);
                            }}
                          />
                          <TextField
                            label="Total Amount"
                            value={formatCurrency(item.quantity && item.rate ? Number(item.quantity) * Number(item.rate) : 0)}
                            variant="outlined"
                            disabled
                          />
                          <IconButton onClick={() => itemHelpers.remove(itemIndex)}>
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        onClick={() => itemHelpers.push({
                          itemId: '',
                          itemCode: '',
                          description: '',
                          quantity: '',
                          quantityUnit: '',
                          rate: '',
                        })}
                      >
                        Add Item
                      </Button>
                    </React.Fragment>
                  )}
                />
              </Stack>
            ))}
            <Button
              onClick={() => arrayHelpers.push({
                sectionNumber: '',
                sectionTitle: '',
                items: [],
              })}
            >
              Add Bill Section
            </Button>
          </React.Fragment>
        )}
      />
    </Stack>
  );
};

export default CreateBillOfMaterialsForm;
