import React from 'react';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';


import ItemsLocationFormModel from '../models/createItemsLocationModel';
import { useGetInventoryLocationsQuery, useGetItemsQuery } from '../../../redux/vmsApi';


const {
    formField: {
        itemId,
        locationId,
        stock,
        totalValue
    },
} = ItemsLocationFormModel;

interface OwnProps {
    editing?: boolean;
}

const CreateItemsLocationForm: React.FC<OwnProps> = (props) => {
    const { editing = false } = props;
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();
    const { data: locations = [] } = useGetInventoryLocationsQuery({});
    const { data: items = [] } = useGetItemsQuery({});

    let total: number = 0;
    const calculateTotalValue = (stock: number, itemId: string) => {
        const selectedItem = items.find((item: any) => item.id === itemId);
        return selectedItem ? stock * selectedItem.itemCost : 0;
      };
    
    return (
        <Stack width="100%" spacing={5}>
            <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                {editing ? 'Edit' : 'Add'}  Item to a Location
            </Typography>
            <Stack direction={'row'} spacing={2}>
                <Field
                    as={TextField}
                    fullWidth
                    select
                    {...itemId}
                    value={values.itemId || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('itemId', e.target.value);
                        const newTotal = calculateTotalValue(values.stock || 0, e.target.value);
                        setFieldValue('totalValue', newTotal.toFixed(2));
                      }}
                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    error={touched[itemId.name] && Boolean(errors[itemId.name])}
                    helperText={touched[itemId.name] && errors[itemId.name] ? errors[itemId.name] : ''}
                >
                    {items.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.itemCode} ({item.description})
                        </MenuItem>
                    ))}
                </Field>

                <Field
                    as={TextField}
                    fullWidth
                    select
                    {...locationId}
                    value={values.locationId || ''}
                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    error={touched[locationId.name] && Boolean(errors[locationId.name])}
                    helperText={touched[locationId.name] && errors[locationId.name] ? errors[locationId.name] : ''}
                >
                    {locations.map((location: any) => (
                        <MenuItem key={location.id} value={location.id}>
                            {location.locationCode} ({location.description})
                        </MenuItem>
                    ))}
                </Field>

                <Field
                    as={TextField}
                    fullWidth
                    {...stock}
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const stockValue = Number(e.target.value) || 0;
                        setFieldValue('stock', stockValue);
                       
                        const newTotal = calculateTotalValue(stockValue, values.itemId);
                        setFieldValue('totalValue', newTotal.toFixed(2));
                      }}
                    error={touched[stock.name] && Boolean(errors[stock.name])}
                    helperText={touched[stock.name] && errors[stock.name] ? errors[stock.name] : ''}
                />
            </Stack>


            <Stack direction="row"  width="100%" spacing={5}>
               
                    <TextField
                        fullWidth
                        {...totalValue} 
                        value={parseFloat(values.totalValue).toFixed(2)||'0.00'} 
                       
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{ backgroundColor: '#f0f4f7', borderRadius: 1 }}
                    />
                
            </Stack>
        </Stack>
    );
};

export default CreateItemsLocationForm;


