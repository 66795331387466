import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Button,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Dialog,
    IconButton,
    TextField,
    Stack,


} from '@mui/material';
import {

    PurchaseOrderWithRelations,
    useGetProcurementProcessesDropdownQuery,
    useGetPurchaseOrdersQuery,

} from '../../../redux/vmsApi';
import { useState } from 'react';
import React from 'react';
import config from "../../../config";
const camelCaseToSpaced = (text: string) => text.replace(/([A-Z])/g, ' $1').trim();
const removeHyphens = (text: string) => text.replace(/-/g, ' ');

const PurchaseOrderList: React.FC = () => {
    const { data: procurementProcesses } = useGetProcurementProcessesDropdownQuery();
    const [filters, setFilters] = useState({
        supplierName: '',
        purchaseOrderNumber: '',
        fromDate: '2023-12-16',
        toDate: '2025-12-16',
    });
    const [documentUrl, setDocumentUrl] = useState<string | null>(null);
    const [openDialog, setOpenDialog] = useState<string | null>(null);
    const [currentOpenRow, setCurrentOpenRow] = useState<string | null>(null);

    const handleToggleDropdown = (rowId: string) => {
        setCurrentOpenRow((prev) => (prev === rowId ? null : rowId));
    };



    const handleViewProcessDetails = (procurementProcessId: string | undefined) => {
        window.location.href = `https://vms.westprop.com/procurement/procurementProcess/${procurementProcessId}`;
        setCurrentOpenRow(null);
    };

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [apiParams, setApiParams] = useState({ ...filters, skip: 0, limit: 10 });

    const { data: purchaseOrders, isLoading, isError } = useGetPurchaseOrdersQuery(apiParams);

    const handleFilterChange = (field: string, value: string) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }));
    };

    const handleApplyFilters = () => {
        setApiParams({
            ...filters,
            skip: 0,
            limit: pageSize,
        });
        setPage(0);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
        setApiParams((prevParams) => ({
            ...prevParams,
            skip: newPage * pageSize,
        }));
    };

    const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
        setPage(0);
        setApiParams((prevParams) => ({
            ...prevParams,
            limit: newSize,
            skip: 0,
        }));
    };

    const handleDialogOpen = (procurementProcessId: string, documentType: string) => {
        if (!procurementProcesses) {
          console.error('Procurement processes are not loaded');
          return;
        }
      
        const procurementProcess = procurementProcesses.find(
          (process) => process.id === procurementProcessId
        );
      
        if (procurementProcess) {
            const documentUrl =
              `${config.CDN_URL}/procurement/${documentType === 'purchaseOrder'
                ? procurementProcess.purchaseOrderDocument?.name
                : procurementProcess.requisitionDocument?.name}`;
      
          if (documentUrl) {
            setDocumentUrl(documentUrl);
            setOpenDialog(documentUrl); 
          } else {
            console.error('Document URL not found');
            setDocumentUrl(null);
            setOpenDialog(null);
          }
        } else {
          console.error('Procurement process not found');
          setDocumentUrl(null);
          setOpenDialog(null);
        }
      };
      
      


    const handleDialogClose = () => setOpenDialog(null);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching purchase orders.</div>;
    }

    return (
        <React.Fragment>
            <Box sx={{ padding: 3, borderRadius: 2, marginBottom: 3, backgroundColor: '#f9f9f9', boxShadow: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Purchase Orders
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={2} alignItems="center">
                    <TextField
                        label="Supplier Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.supplierName}
                        onChange={(e) => handleFilterChange('supplierName', e.target.value)}
                    />
                    <TextField
                        label="Purchase Order Number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.purchaseOrderNumber}
                        onChange={(e) => handleFilterChange('purchaseOrderNumber', e.target.value)}
                    />
                    <TextField
                        label="From Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.fromDate}
                        onChange={(e) => handleFilterChange('fromDate', e.target.value)}
                    />
                    <TextField
                        label="To Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        fullWidth
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                        value={filters.toDate}
                        onChange={(e) => handleFilterChange('toDate', e.target.value)}
                    />
                </Box>
                <Stack direction="row" spacing={2} mt={2}>
                    <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        onClick={handleApplyFilters}
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            color: '#1976d2',
                            borderColor: '#1976d2',
                            '&:hover': {
                                backgroundColor: '#e3f2fd',
                                borderColor: '#1565c0',
                            },
                        }}
                    >
                        Apply Filters
                    </Button>
                </Stack>
            </Box>

            <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#1976d2' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PO Number</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>REQ Number</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Development</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="left">Supplier</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="center">Date</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="left">View Purchase Order Document</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="left">View Requisition Document</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseOrders && purchaseOrders.length > 0 ? (
                            purchaseOrders.map((purchaseOrder: PurchaseOrderWithRelations, index: number) => (
                                <TableRow key={purchaseOrder.id} sx={{ backgroundColor: index % 2 === 0 ? '#f0f4f7' : 'white' }}>
                                    <TableCell component="th" scope="row">
                                        {purchaseOrder.purchaseOrderNumber || 'N/A'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {purchaseOrder.reqNumber || 'N/A'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {purchaseOrder.development || 'N/A'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {purchaseOrder.quotation.supplier.name || 'N/A'}
                                    </TableCell>

                                    <TableCell align="right">
                                        {purchaseOrder.date || 'N/A'}
                                    </TableCell>

                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleDialogOpen(purchaseOrder.procurementProcessId || '', 'purchaseOrder')}
                                            sx={{ color: '#1976d2' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>

                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleDialogOpen(purchaseOrder.procurementProcessId || '', 'requisition')}
                                            sx={{ color: '#1976d2' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>

                                   
                                    
                                    <TableCell align="right">
                                        <Box sx={{ position: "relative", display: "inline-block" }}>
                                            <Button
                                                sx={{
                                                    backgroundColor: "primary.main",
                                                    color: "white",
                                                    "&:hover": { backgroundColor: "primary.dark" },
                                                }}
                                                onClick={() => handleToggleDropdown(purchaseOrder.procurementProcessId || "")}
                                            >
                                                Actions
                                            </Button>
                                            {currentOpenRow === purchaseOrder.procurementProcessId && (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        top: "100%",
                                                        left: 0,
                                                        zIndex: 1,
                                                        mt: 1,
                                                        minWidth: "150px",
                                                        backgroundColor: "white",
                                                        boxShadow: 3,
                                                        borderRadius: 1,
                                                        overflow: "hidden",
                                                    }}
                                                >

                                                    <Button
                                                        sx={{
                                                            display: "block",
                                                            width: "100%",
                                                            textAlign: "left",
                                                            px: 2,
                                                            py: 1,
                                                            "&:hover": { backgroundColor: "grey.100" },
                                                        }}
                                                        onClick={() => handleViewProcessDetails(purchaseOrder.procurementProcessId)}
                                                    >
                                                        Process
                                                    </Button>

                                                </Box>
                                            )}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={9} align="center">No matching Purchase Orders found.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <Button
                    onClick={() => handlePageChange(Math.max(page - 1, 0))}
                    disabled={page === 0}
                    variant="contained"
                    sx={{ marginRight: 2 }}
                >
                    Previous
                </Button>

                <Typography variant="body1">Page {page + 1}</Typography>

                <Button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={purchaseOrders?.length === undefined || purchaseOrders.length < pageSize}
                    variant="contained"
                    sx={{ marginLeft: 2 }}
                >
                    Next
                </Button>
            </Box>


            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body2" sx={{ marginRight: 2 }}>Rows per page:</Typography>
                <FormControl variant="outlined" size="small">
                    <Select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        sx={{ backgroundColor: '#f0f4f7' }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Dialog open={!!openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
                <Box p={2}>
                    {openDialog && (
                        <iframe
                            src={openDialog}
                            title="Document Viewer"
                            width="100%"
                            height="500px"
                            style={{ border: 'none' }}
                        />
                    )}
                    <Box textAlign="right" mt={2}>
                        <Button onClick={handleDialogClose} variant="contained" color="primary">
                            Close
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default PurchaseOrderList;