import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Paper,
} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { push } from "connected-react-router/immutable";
import { useAppDispatch } from "../../redux/hooks";
import {
  useGet360ReviewsByUserByUserIdQuery,
  FeedbackSurveyWithRelations,
} from "../../redux/vmsApi";

interface OwnProps {
  subjectUserId: string;
  admin?: boolean;
}

// Utility function to generate past quarters
const generatePastQuarters = () => {
  const quarters = [];
  const currentDate = new Date();
  let month = currentDate.getMonth();
  const year = currentDate.getFullYear();

  for (let i = 0; i < 4; i++) {
    if (month < 0) {
      month += 12;
    }
    const quarter = Math.floor(month / 3);
    const quarterLabel = `Q${quarter + 1} ${year - (quarter === 3 ? 1 : 0)}`;
    quarters.push(quarterLabel);
    month -= 3;
  }
  return quarters;
};

// Mapping of question IDs to full questions
const questionMapping: { [key: string]: string } = {
  respectValues: 'Respect the WestProp Values evident in conduct',
  decisionValues: 'Makes decisions in line with WestProp values',
  encourageValues: 'Encourages team members to live the WestProp values',
  improveValues: 'What suggestions do you have to help improve living the WestProp values',
  goodDecisions: 'Makes good decisions and trusts the team when delegating tasks',
  highStandards: 'The team is working to a high standard and cohesively towards the high-level goals and objectives of the department',
  motivates: 'Motivates others to reach their goals',
  improveLeadership: 'What suggestions do you have to help improve leadership skills',
  controlEmotions: 'Controls emotions even in high pressure situations',
  awareness: 'Demonstrates an awareness of how actions and decisions affect others',
  strongSkills: 'Has strong skills and is able to interact well with others',
  empathy: 'Demonstrates empathy and is able to recognize and respond appropriately to the emotional state of others',
  selfMotivated: 'Is self motivated, striving to achieve goals and always looking for ways to do better',
  improveEmotional: 'What suggestions do you have to help improve emotional intelligence',
  urgentWork: 'Delivers urgent work without compromising on the details',
  meetsDeadlines: 'Meets deadlines and makes the best time use',
  exceedsExpectations: 'Exceeds expectations by delivering more than assigned work despite the tight schedule',
  improveProductivity: 'What suggestions do you have to help improve productivity',
  fullUnderstanding: 'Has full understanding of role and responsibility in the department',
  expertSkills: 'Is an expert and performs job responsibilities skillfully',
  improveJobKnowledge: 'What suggestions do you have to help improve job knowledge',
  gracefulDisagree: 'Even when disagreeing with others, does it gracefully and respectfully',
  adaptsEasily: 'Adapts easily to various situations and different people',
  equalTreatment: 'Equal in treatment of everyone and communicates with peers respectfully',
  improveInterpersonal: 'What suggestions do you have to help improve interpersonal skills',
  clearGoalsSelf: 'Sets clear goals, objectives and targets for self',
  clearGoalsTeam: 'Sets and communicates clear goals, objectives and targets for the team',
  performanceReviews: 'Conducts performance review meetings/appraisals with the team timorously and objectively',
  manageUnderperformance: 'Is proactive in managing underperformance of the team by initiating and monitoring appropriate interventions e.g. training',
  improvePerformance: 'What suggestions do you have to help improve performance management and execution',
  strength: 'What is the greatest strength and what should continue to do to grow and develop?',
  opportunity: 'What is the greatest opportunity and what can be done to improve performance?',
};

const sections = [
  { title: 'VALUES', questions: ['respectValues', 'decisionValues', 'encourageValues', 'improveValues'] },
  { title: 'LEADERSHIP SKILLS', questions: ['goodDecisions', 'highStandards', 'motivates', 'improveLeadership'] },
  { title: 'EMOTIONAL INTELLIGENCE', questions: ['controlEmotions', 'awareness', 'strongSkills', 'empathy', 'selfMotivated', 'improveEmotional'] },
  { title: 'PRODUCTIVITY', questions: ['urgentWork', 'meetsDeadlines', 'exceedsExpectations', 'improveProductivity'] },
  { title: 'JOB KNOWLEDGE', questions: ['fullUnderstanding', 'expertSkills', 'improveJobKnowledge'] },
  { title: 'INTERPERSONAL SKILLS', questions: ['gracefulDisagree', 'adaptsEasily', 'equalTreatment', 'improveInterpersonal'] },
  { title: 'PERFORMANCE MANAGEMENT', questions: ['clearGoalsSelf', 'clearGoalsTeam', 'performanceReviews', 'manageUnderperformance', 'improvePerformance'] },
];

const FeedbackSurveyResponses: React.FC<OwnProps> = ({subjectUserId, admin=false}) => {
  const dispatch = useAppDispatch();
  const [selectedQuarter, setSelectedQuarter] = useState<string>('');
  const [reviews, setReviews] = useState<FeedbackSurveyWithRelations[]>([]);
  const { data: userReviews } = useGet360ReviewsByUserByUserIdQuery({ userId: subjectUserId }, { skip: !subjectUserId });

  const pastQuarters = generatePastQuarters();

  useEffect(() => {
    if (userReviews) {
      setReviews(userReviews.filter(review => review.reviewPeriod === selectedQuarter));
    }
  }, [userReviews, selectedQuarter]);

  const handleQuarterChange = (event: SelectChangeEvent) => {
    setSelectedQuarter(event.target.value);
  };

  const processDataForBarChart = (section: string) => {
    const data = sections.find(s => s.title === section)?.questions.filter(q => !q.includes('improve')).map(questionId => {
      const responses = reviews.flatMap(review => review.responses.filter(response => response.questionId === questionId));
      const trueCount = responses.filter(r => r.responseBoolean === true).length;
      const falseCount = responses.filter(r => r.responseBoolean === false).length;
      return {
        question: questionMapping[questionId],
        true: trueCount,
        false: falseCount,
      };
    });
    return data || [];
  };

  const chartSettings = {
    width: 700,
    height: 400,
    layout: 'horizontal' as const,
  };

  return (
    <Stack spacing={4} width="100%" p={10} pb={5}>
        {!admin &&
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button color="primary" onClick={() => dispatch(push('/reviews'))}>
                <ArrowBackIcon/>
                <Typography color="primary" align="left">Dashboard</Typography>
              </Button>
            </Grid>
          </Grid>
        }
        <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
          Feedback Overview
        </Typography>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Review Period</InputLabel>
          <Select
            label="Review Period"
            value={selectedQuarter}
            onChange={handleQuarterChange}
          >
            {pastQuarters.map((quarter, index) => (
              <MenuItem key={index} value={quarter}>{quarter}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {reviews.length > 0 && (
          <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant='h6' gutterBottom style={{ color: '#123456' }}>
              Responses for {selectedQuarter}
            </Typography>
            {sections.map((section, sectionIndex) => (
              <Grid container spacing={2} key={sectionIndex} style={{ marginBottom: '40px' }}>
                <Grid item xs={6}>
                  <BarChart
                    series={[
                      { dataKey: 'true', label: 'Yes', stack: 'A', color: '#4caf50' },
                      { dataKey: 'false', label: 'No', stack: 'A', color: '#f44336' },
                    ]}
                    yAxis={[{ scaleType: 'band', dataKey: 'question' }]}
                    dataset={processDataForBarChart(section.title)}
                    {...chartSettings}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ marginBottom: '20px' }}>
                    {section.title}
                  </Typography>
                  {section.questions.map(questionId => {
                    const textResponses = reviews.flatMap(review => review.responses.filter(response => response.questionId === questionId && response.responseText));
                    if (textResponses.length > 0) {
                      return (
                        <div key={questionId} style={{ marginBottom: '20px' }}>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            {questionMapping[questionId]}
                          </Typography>
                          <Typography variant="body2" style={{ marginBottom: '10px' }}>
                            {textResponses.length} responses
                          </Typography>
                          {textResponses.map((response, responseIndex) => (
                            <Paper key={responseIndex} style={{ padding: '10px', marginBottom: '10px', backgroundColor: '#f9f9f9' }}>
                              <Typography variant="body2">{response.responseText}</Typography>
                            </Paper>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  })}
                </Grid>
              </Grid>
            ))}
          </Paper>
        )}
      </Stack>
  );
};

export default FeedbackSurveyResponses;
