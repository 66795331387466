const BackOrdersFormModel = {
    formId: 'backOrderForm',
    formField: {
      quantity: {
        name: 'quantity',
        label: 'Quantity',
        required: true,
        requiredErrorMsg: 'Quantity is required',
      },
      itemId: {
        name: 'itemId',
        label: 'Item Code',
        required: true,
        requiredErrorMsg: 'item code is required',
      },
      stakeholderId: {
        name: 'stakeholderId',
        label: 'Supplier',
        required: true,
        requiredErrorMsg: 'Supplier  is required',
      },
      purchaseOrderId: {
        name: 'purchaseOrderId',
        label: 'purchaseOrder',
        required: true,
        requiredErrorMsg: 'Supplier  is required',
      },
      procurementProcessId: {
        name: 'procurementProcessId',
        label: 'procurementProcess',
        required: true,
        requiredErrorMsg: 'procurementProcessId  is required',
      },
      totalValue: {
        name: 'totalValue',
        label: 'Total Value',
        required: true,
        requiredErrorMsg: 'Location is required',
      },
    },
    initialValues: {
        itemId: '',
        stakeholderId:'',
        purchaseOrderId:'',
        procurementProcessId:'',
        totalValue:'',
        quantity:'',
       
    },
  };
  
  export type createBackOrderModelType = {
    itemId: string;
    stakeholderId: string;
    purchaseOrderId:string,
    procurementProcessId:string,
    quantity: string;
    totalValue:string;
  };
  
  export default BackOrdersFormModel;
  