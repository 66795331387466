import React, { useState } from 'react';
import {
  BillOfMaterial,
  useGetBomsQuery,
  usePatchBomByIdMutation,
  useGetBomByIdQuery,
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {formatCurrency} from "../../../utils/currencyUtil";

const BillsOfMaterialList: React.FC = () => {
  const { data: boms, isLoading, isError } = useGetBomsQuery({});
  const [patchBom] = usePatchBomByIdMutation();
  const [selectedBOMId, setSelectedBOMId] = useState<string | null>(null);
  const { data: selectedBOM } = useGetBomByIdQuery(
    { id: selectedBOMId ?? '' },
    { skip: !selectedBOMId }
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleViewClick = (bomId: string) => {
    setSelectedBOMId(bomId);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedBOMId(null);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching BOMs.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        View Bills of Material
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Unit ID</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {boms?.map((bom: BillOfMaterial) => (
              <TableRow key={bom.id}>
                <TableCell>{bom.unitId}</TableCell>
                <TableCell>
                  <Button onClick={() => handleViewClick(bom.id ?? '')}>View BOM</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedBOM && (
        <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
          <DialogTitle>Bill of Material</DialogTitle>
          <DialogContent>
            {selectedBOM.sections.map((section, sectionIndex) => (
              <Box key={sectionIndex} mb={2}>
                <Typography variant="h6">{section.sectionTitle}</Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Item ID</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Quantity Unit</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>Total Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {section.items.map((item, itemIndex) => (
                        <TableRow key={itemIndex}>
                          <TableCell>{item.itemId}</TableCell>
                          <TableCell>{item.description}</TableCell>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell>{item.quantityUnit}</TableCell>
                          <TableCell>{formatCurrency(item.rate)}</TableCell>
                          <TableCell>{formatCurrency(item.quantity * item.rate)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default BillsOfMaterialList;
